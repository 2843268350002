import { Control } from 'react-hook-form';
import { StateCreator } from 'zustand';
import { BoundStore } from '..';

export type FormName =
  | 'UpdateNameAndCity'
  | 'UpsertCertification'
  | 'UpsertExperience'
  | 'CreateBusiness'
  | 'UpdateBusiness'
  | 'UpsertJob'
  | 'InviteTeammate'
  | 'UpdateUser'
  | 'UpdateUserLocation'
  | 'UpdateWorkPreferences'
  | 'UpdateJobPreferences'
  | 'SendOtp'
  | 'CheckOtp'
  | 'AddNewBusiness'
  | 'AddNewAddress';

type FormState = {
  isSubmitting: boolean;
  isDirty: boolean;
  isValid: boolean;
  isSubmitSuccessful: boolean;
  isSubmitted: boolean;
};

export type FormStore = {
  id: string;
  name: FormName;
  initialized: boolean;
  state: FormState;
  control?: Control<any, any>;
};

export type FormSlice = {
  form: {
    forms: Record<FormName, FormStore> | Record<string, undefined>;
    register: (formName: FormName, control: Control<any, any>) => void;
    unregister: (formName: FormName) => void;
    setFormState: (formName: FormName, formState: FormState) => void;
  };
};

export const createFormSlice: StateCreator<BoundStore, [], [], FormSlice> = (
  set
) => ({
  form: {
    forms: {},
    register: (formName, control) =>
      // @ts-ignore
      set((state) => {
        return {
          ...state,
          form: {
            ...state.form,
            forms: {
              ...state.form.forms,
              [formName]: {
                initialized: true,
                id: `form_${formName}`,
                control,
                name: formName,
                state: {
                  isDirty: false,
                  isValid: false,
                  isSubmitting: false,
                  isSubmitSuccessful: false,
                  isSubmitted: false,
                },
              },
            },
          },
        };
      }),
    unregister: (formName) =>
      set((state) => {
        return {
          ...state,
          form: {
            ...state.form,
            forms: {
              ...state.form.forms,
              [formName]: undefined,
            },
          },
        };
      }),
    setFormState: (formName, formState) =>
      // @ts-ignore
      set((state) => {
        const { forms } = state.form;
        const form = forms[formName];
        if (!form) return { ...state };
        return {
          ...state,
          form: {
            ...state.form,
            forms: {
              ...state.form.forms,
              [formName]: {
                ...form,
                state: formState,
              },
            },
          },
        };
      }),
  },
});
